<template>
    <div class="top-footer-container">
        <b-container>
            <b-row>
                <img class="logo" width="170" src="/assets/landing/images/footer/top-footer/logo.svg"/>
            </b-row>
            <b-row v-if="$route.path=='/site' || $route.path=='/site/home' " class="links">
                <router-link tag="span" :to="{name: link.target}" v-for="(link,index) in topFooter.items" :key="index"
                             class="footer-link">{{link.title}}
                </router-link>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        props: ["topFooter"],
        name: "TopFooter",
        data() {
            return {}
        },
        computed: {},
        methods: {}
    }
</script>

<style>

</style>
