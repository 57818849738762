<template>

    <div id="header">
    <b-container>
      <span v-if="hero.route"
      class="route">{{hero.route.parent}} / <span>{{hero.route.child}}</span>
      <span v-if="hero.route.child2">/ {{hero.route.child2}}</span>
      </span>
      <h2 v-if="hero.client" class="f-normal t-c">{{$t('our_clients.header')}}</h2>
     <b-row v-if="!hero.client" class="hero_div">
    <img v-if="$route.path=='/site/users-manual' || $route.path=='/site/privacy-policy' "
     class="repeat-grid" src="/assets/landing/images/hero/repeat-grid.svg" alt="">
    <b-col class="mt--100"
    :lg="$route.path=='/site/users-manual' || $route.path=='/site/privacy-policy' || $route.path =='/site/faq' ?12:5"
    :md="$route.path=='/site/users-manual' || $route.path=='/site/privacy-policy' || $route.path =='/site/faq'?12:5"
    sm="12">
      <img v-if="$route.path!=='/site/users-manual' && $route.path!=='/site/privacy-policy'" class="repeat-grid" src="/assets/landing/images/hero/repeat-grid.svg" alt="">
      <h2 v-if="$route.path=='/site'|| $route.path=='/' || $route.path=='/site/'">{{header.title}}</h2>
      <h2 v-else :class="$route.path=='/site/users-manual' ||
      $route.path=='/site/privacy-policy' || $route.path =='/site/faq'?'t-c':''
      "
      :style="$route.path=='/site/users-manual' || $route.path=='/site/privacy-policy'?'margin-top:-100px;':''">{{hero.header}}</h2>
      <h3 v-if="$route.path=='/site/privacy-policy' || $route.path =='/site/faq'"
      class="t-c blue-clr mt-15"
      >{{hero.header2}}</h3>

      <p  v-if="$route.path=='/site'|| $route.path=='/' || $route.path=='/site/'">{{header.description}}</p>
      <p  v-else
      :class="$route.path=='/site/users-manual' ||
       $route.path=='/site/privacy-policy' || $route.path =='/site/faq' ?'t-c pad-r-160 pad-160':''">{{hero.sub_header}}</p>

      <b-button @click="goTo(header.btn.target)"  v-if="$route.path=='/site'|| $route.path=='/' || $route.path=='/site/'" class="w-140 try-btn">
      {{header.btn.title}}
      </b-button>
        <b-button  v-else-if="hero.footer" class="w-140 try-btn">{{hero.footer}}</b-button>
      <div v-else-if="hero.footer2" class="pos-r-50 pad-t-50 pad-b-50 pos-r-300 text-center footer2">
          <span  class="blue-clr">{{hero.footer2}}</span>
      </div>
    </b-col>
    <b-col lg="7" md="7" sm="12">
        <img v-if="$route.path=='/site'|| $route.path=='/' || $route.path=='/site/'" class="f-l w-400 hero_img"
        :src="header.image" alt="">
      <img v-else-if="hero.img" class="f-l w-400 hero_img" :src="hero.img" alt="">
      <iframe class="pos-t-50" v-else-if="hero.video" width="560" height="370"
              :src="hero.video">
              </iframe>
    </b-col>
  </b-row>
  <b-row v-else class="mt-50">

    <b-col class="t-r" v-for="(client,key) in clients" :key="key" >
        <div class="mt-30">
        <img  class="mx-auto"
         :src="client.icon" alt="" title="" />
        </div>
    </b-col>
  </b-row>
    </b-container>
    </div>
</template>

<script>
export default {
props:["hero","header"],
  computed:{
        lang(){return localStorage.getItem('landing_lang')}
    },
    data(){
        return {
            clients: [
                {
                    id:1,
                    icon:"/assets/landing/images/clients/hero/header/1.svg"
                },
                 {
                    id:2,
                    icon:"/assets/landing/images/clients/hero/header/2.svg"
                },
                 {
                    id:3,
                    icon:"/assets/landing/images/clients/hero/header/3.svg"
                },
                 {
                    id:4,
                    icon:"/assets/landing/images/clients/hero/header/4.svg"
                },
                 {
                    id:5,
                    icon:"/assets/landing/images/clients/hero/header/5.svg"
                },
                 {
                    id:6,
                    icon:"/assets/landing/images/clients/hero/header/6.svg"
                },
                {
                    id:7,
                    icon:"/assets/landing/images/clients/hero/header/7.svg"
                },
                {
                    id:8,
                    icon:"/assets/landing/images/clients/hero/header/8.svg"
                },
                {
                    id:9,
                    icon:"/assets/landing/images/clients/hero/header/9.svg"
                },
                {
                    id:10,
                    icon:"/assets/landing/images/clients/hero/header/10.svg"
                },
                 {
                    id:11,
                    icon:"/assets/landing/images/clients/hero/header/11.svg"
                },
                {
                    id:12,
                    icon:"/assets/landing/images/clients/hero/header/12.svg"
                },
                {
                    id:13,
                    icon:"/assets/landing/images/clients/hero/header/13.svg"
                },
                 {
                    id:14,
                    icon:"/assets/landing/images/clients/hero/header/14.svg"
                },
                 {
                    id:15,
                    icon:"/assets/landing/images/clients/hero/header/15.svg"
                },
                 {
                    id:16,
                    icon:"/assets/landing/images/clients/hero/header/16.svg"
                },
                 {
                    id:17,
                    icon:"/assets/landing/images/clients/hero/header/17.svg"
                },
                 {
                    id:18,
                    icon:"/assets/landing/images/clients/hero/header/18.svg"
                },
                 {
                    id:19,
                    icon:"/assets/landing/images/clients/hero/header/19.svg"
                },
                 {
                    id:20,
                    icon:"/assets/landing/images/clients/hero/header/20.svg"
                },

            ]
        }
    },
    methods:{
     goTo(page){
      this.$router.push(page)
    },
    }
}
</script>

<style>


</style>
