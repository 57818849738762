<template>
    <b-container class="bv-example-row">
        <b-row class="mt-5">
            <b-col class="bunch-col mt-5">
                <div class="aptus-bunches">
                    <p>{{$t('bunches.bunch1.title')}}</p>
                </div>
                <div class="aptus-bunches-header">
                    <p>{{$t('bunches.bunch1.price')}}</p>
                </div>
                <div class="aptus-bunches-sub-header mr-5">
                    <p>{{$t('bunches.bunch1.item1')}}</p>
                    <p>{{$t('bunches.bunch1.item2')}}</p>
                    <p>{{$t('bunches.bunch1.item3')}}</p>
                    <p>{{$t('bunches.bunch1.item4')}}</p>
                    <p>{{$t('bunches.bunch1.item5')}}</p>
                    <p>{{$t('bunches.bunch1.item6')}}</p>
                </div>
                <div class="aptus-bunches-btn">
                    <b-button class="" style="">{{$t('bunch.btn')}}</b-button>
                </div>
            </b-col>
            <b-col class="bunch-col-active mr-5 mt-5">
                <div class="aptus-bunches-active">
                    <p>{{$t('bunches.bunch2.title')}}</p>
                </div>
                <div class="aptus-bunches-header-active">
                    <p>{{$t('bunches.bunch2.price')}}</p>
                </div>
                <div class="aptus-bunches-sub-header-active mr-5">
                    <p>{{$t('bunches.bunch2.item1')}}</p>
                    <p>{{$t('bunches.bunch2.item2')}}</p>
                    <p>{{$t('bunches.bunch2.item3')}}</p>
                    <p>{{$t('bunches.bunch2.item4')}}</p>
                    <p>{{$t('bunches.bunch2.item5')}}</p>
                </div>
                <div class="aptus-bunches-btn-active mt-5">
                    <b-button class="" style="">{{$t('bunch.btn')}}</b-button>
                </div>
            </b-col>
            <b-col class="bunch-col mr-5 mt-5">
                <div class="aptus-bunches">
                    <p>{{$t('bunches.bunch3.title')}}</p>
                </div>
                <div class="aptus-bunches-header">
                    <p>{{$t('bunches.bunch3.price')}}</p>
                </div>
                <div class="aptus-bunches-sub-header mr-5">
                    <p>{{$t('bunches.bunch3.item1')}}</p>
                    <p>{{$t('bunches.bunch3.item2')}}</p>
                    <p>{{$t('bunches.bunch3.item3')}}</p>
                    <p>{{$t('bunches.bunch3.item4')}}</p>
                    <p>{{$t('bunches.bunch3.item5')}}</p>
                </div>
                <div class="aptus-bunches-btn mt-5">
                    <b-button class="" style="">{{$t('bunch.btn')}}</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row  align-h="left">

        </b-row>
        <b-row class="mt-5 ml-5" align-h="center">
            <b-col cols="4">
                <div class="bunches-compare">
                    <b-button class="pl-5 pr-5">{{$t('bunch.compare')}}</b-button>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>
