export default [
    {
        path:"/",
        redirect: "/site"
    },
    {
        path: "/site",
        component: () => import("../views/Index.vue"),
        children: [
            {
                path: "/",
                name: 'home.index',
                component: () => import("../views/Home.vue")
            },
            {
                path: "home",
                name: 'home.show',
                component: () => import("../views/Home.vue")
            },
            {
                path: "about",
                name: 'about',
                component: () => import("../views/About.vue")
            },
            {
                path: "features",
                name: 'features',
                component: () => import("../views/Features.vue")
            },
            {
                path: "programs",
                name: 'programs',
                component: () => import("../views/Index.vue"),
                children: [
                    {
                        path: "",
                        name: 'programs',
                        component: () => import("../views/Programs.vue"),
                    },
                    {
                        path: "accounting",
                        name: 'accounting',
                        component: () => import("../views/AccountingProgram.vue")
                    },
                ]
            },
            {
                path: "clients",
                name: 'clients',
                component: () => import("../views/Clients.vue")
            },
            {
                path: "said-about-us",
                name: 'said-about-us',
                component: () => import("../views/SaidAboutUs.vue")
            },
            {
                path: "reports",
                name: 'reports',
                component: () => import("../views/Reports.vue")
            },
            {
                path: "pages/details",
                name: "user.details",
                component: () => import('../views/UserManualDetails')
            },
            {
                path: "users-manual",
                name: 'users-manual',
                component: () => import("../views/UsersManual.vue")
            },
            {
                path: "privacy-policy",
                name: 'privacy-policy',
                component: () => import("../views/PrivacyPolicy.vue")
            },
            {
                path: 'bunches',
                name: 'bunches.index',
                component: () => import('../views/Bunches')
            },
            {
                path: "bunches-details",
                name: 'bunches-details',
                component: () => import("../views/BunchesDetails.vue")
            },
            {
                path: "faq",
                name: 'faq',
                component: () => import("../views/Faq.vue")
            },
            {
                path: "request-experiment",
                name: 'request-experiment',
                component: () => import("../views/RequestExperiment.vue")
            },
            {
                path: "support",
                name: 'support',
                component: () => import('../views/Support')
            }
        ]
    },
            ];
