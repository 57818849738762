<template>
 <section id="programs">
        <div id="program">
        <h2>{{programs.title}}</h2>
        <p class="blue-clr">{{programs.sub_title}} </p>
        </div>
        <div id="program-boxes">
            <b-container>
                  <b-row>
     <b-col v-for="(program,key) in programs.items" :key="key" lg="3" md="4" sm="6">
        <div @click="goTo(program.target)" class="box">
        <img  class="p-icon"
         :src="program.image" alt="" title="" />
         <h5 >{{program.title}}</h5>
         <p >{{program.description}}</p>
        </div>
    </b-col>
    </b-row>
    <b-row>
        <div  class="mx-auto">
         <b-button @click="goTo(programs.btn.target)" class="try-btn w-140 mt-15 mb-30">{{programs.btn.title}}</b-button>
     </div>
    </b-row>
            </b-container>
        </div>
  </section>
</template>

<script>
export default {
        props:["programs"],
     data(){
        return {
        }
    },
    methods:{
        goTo(page) {
    this.$router.push(page);
  },
    }
}
</script>

<style>

</style>
