<template>
 <div>
     <div class="said-about-us-circle">
        <img src="/assets/landing/images/said-about-us-page/said-about-us2/circle.svg" alt="">
     </div>
      <section id="said-about-us" class="h-auto">
              <b-container>
                  <b-row>
        <b-col v-for="(customer,key) in customers" :key="key" lg="4" md="6" sm="12">
        <div class="customer">
            <div class="customer-block">
            <img class="customer-img" :src="customer.img" alt="">
             <div class="customer-details">
             <p  class="customer-name">{{customer.name}}</p>
             <p  class="customer-job">{{customer.job}}</p>
             </div>
            </div>          
             <li class="clearfix">
            <div class="message me-message">{{customer.msg}}</div>
          </li> 
        </div>

    </b-col>
        </b-row>
              </b-container>
  </section>

    <videos2></videos2>
 </div> 
</template>

<script>
export default {
data(){
    return {
        customers: [
            {
                id:1,
                img:"/assets/landing/images/said-about-us/customers/1.svg",
                name:this.$t('saidAboutUs2.c1.name'),
                job:this.$t('saidAboutUs2.c1.job'),
                msg:this.$t('saidAboutUs2.c1.msg'),
                },
            {
                id:2,
                img:"/assets/landing/images/said-about-us/customers/2.svg",
                name:this.$t('saidAboutUs2.c2.name'),
                job:this.$t('saidAboutUs2.c2.job'),
                msg:this.$t('saidAboutUs2.c2.msg'),
           },
            {
                id:3,
                img:"/assets/landing/images/said-about-us/customers/3.svg",
                name:this.$t('saidAboutUs2.c3.name'),
                job:this.$t('saidAboutUs2.c3.job'),
                msg:this.$t('saidAboutUs2.c3.msg'),
            },
            {
                id:4,
                img:"/assets/landing/images/said-about-us/customers/1.svg",
                name:this.$t('saidAboutUs2.c4.name'),
                job:this.$t('saidAboutUs2.c4.job'),
                msg:this.$t('saidAboutUs2.c4.msg'),
            },
            {
                id:5,
                img:"/assets/landing/images/said-about-us/customers/2.svg",
                name:this.$t('saidAboutUs2.c5.name'),
                job:this.$t('saidAboutUs2.c5.job'),
                msg:this.$t('saidAboutUs2.c5.msg'),
            },
            {
                id:6,
                img:"/assets/landing/images/said-about-us/customers/3.svg",
                name:this.$t('saidAboutUs2.c6.name'),
                job:this.$t('saidAboutUs2.c6.job'),
                msg:this.$t('saidAboutUs2.c6.msg'),
            }

           
        ]
    }
}
}
</script>

<style>
</style>