<template>
    <section id="features3">
        <div  class="features">
         <img src="/assets/landing/images/features-page/features3/grid.svg" alt="">
        </div>
        <b-container>
            <b-row class="what-is-row t-r pad-t-50">
                          <b-col  v-for="(w,key) in whatIsItArray" :key="key" lg="6" md="4" sm="6" class="what-nav-box">
                            <ul  @mouseover="hoverIcon(w.icon)" @mouseout="removeHoverIcon(w.icon)" class="what-nav list-style-none white-bg mt-30 h-250">
                                <li><img :id="w.icon" :src="'/assets/landing/images/features-page/features3/'+w.icon+'.svg'" class="what-img"></li>
                                <li class="what-card-title">{{w.title}}</li>
                                <li class="what-card-description">{{w.details}}</li>
                            </ul>
                        </b-col>
                    </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
        data(){
            return {
                whatIsItArray:[
                    {
                        id:1,
                        icon:"cash-register",
                        title:this.$t('features3.cashRegister.title'),
                        details:this.$t('features3.cashRegister.details'),
                    },
                     {
                        id:2,
                        icon:"payment",
                        title:this.$t('features3.payment.title'),
                        details:this.$t('features3.payment.details'),
                    },
                     {
                        id:3,
                        icon:"sms",
                        title:this.$t('features3.sms.title'),
                        details:this.$t('features3.sms.details'),
                    },
                     {
                        id:4,
                        icon:"code-working",
                        title:this.$t('features3.codeWorking.title'),
                        details:this.$t('features3.codeWorking.details'),
                    }
                ]
            }
        },
        methods:{
            hoverIcon(icon) {
                $('#'+icon).attr("src","/assets/landing/images/features-page/features3/" + icon +"-active" +".svg")
            },
            removeHoverIcon(icon){
                $('#'+icon).attr("src","/assets/landing/images/features-page/features3/" + icon  +".svg")
            }
        }
}
</script>

<style>

</style>