import Vue from 'vue'
import App from './App'
import './component';
import router from './router'
import i18n from "./lang/lang";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// require('../plugins/jquery');
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app');
