<template>
 <section id="features">
        <div id="feature">
        <img class="feature-grid pos-l-250 r-grid" src="/assets/landing/images/features/repeat-grid.svg" alt="">
        <h2 class="w-600 center-block">{{$t('about_page.features_inline.header')}}</h2>
        <img class="feature-grid f-r pos-t--80 part-circle" src="/assets/landing/images/features/part-circle.svg" alt="">
        </div>
        <div id="feature-boxes">
            <b-container>
                  <b-row>
    <b-col v-for="(feature,key) in features" :key="key" lg="3" md="4" sm="6">
        <div class="f-r pad-t-30 pos-r-35 feature_box">
        <img :src="feature.icon" alt="" title="" />
         <span  class="pad-r-15 grey-light-clr">{{feature.title}}</span>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
  </section> 
</template>

<script>
export default {
    data(){ 
        return {
            features: [
                {
                    id:1,
                    title:this.$t('features_inline.easeOfUse'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                 {
                    id:2,
                    title:this.$t('features_inline.customOptions'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                 {
                    id:3,
                    title:this.$t('features_inline.quickArrival'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                 {
                    id:4,
                    title:this.$t('features_inline.continuouslyUpdated'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:5,
                    title:this.$t('features_inline.compatible'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:6,
                    title:this.$t('features_inline.otp'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:7,
                    title:this.$t('features_inline.privateServers'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:8,
                    title:this.$t('features_inline.cloudSystem'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:9,
                    title:this.$t('features_inline.safeAndSecure'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:10,
                    title:this.$t('features_inline.unifiedSystem'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:11,
                    title:this.$t('features_inline.continuousBackup'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                  {
                    id:12,
                    title:this.$t('features_inline.advancedTechnologies'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                {
                    id:13,
                    title:this.$t('features_inline.integratedSystem'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                {
                    id:14,
                    title:this.$t('features_inline.extendedCustomizations'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                {
                    id:15,
                    title:this.$t('features_inline.mailAlerts'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
                {
                    id:16,
                    title:this.$t('features_inline.continuouslyUpdated'),
                    icon:"/assets/landing/images/about/features-inline/elipse.svg"
                },
            ]
        }
    }
}
</script>

<style>

</style>