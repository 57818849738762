<template>
  <section id="request-experiment" class="t-r">
       <b-container>
            <b-row align-h="center">
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                               >
                                <b-form-input
                                    class="request-experiment-input"
                                    :placeholder="$t('request_experiment_form.name')"
                                    v-model="form.name"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                >
                                <b-form-input
                                    class="request-experiment-input"
                                   :placeholder="$t('request_experiment_form.company')"
                                    v-model="form.company"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="request-experiment-input"
                                   :placeholder="$t('request_experiment_form.email')"
                                     v-model="form.email"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                >
                                <b-form-input
                                    class="request-experiment-input"
                                    :placeholder="$t('request_experiment_form.phone')"
                                    v-model="form.phone"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-15">
                        <b-col>
                        <b-form-select class="request-experiment-input" v-model="form.position"
                         :options="form.options">
                         </b-form-select>
                        </b-col>
                         <b-col>
                        <b-form-select class="request-experiment-input" v-model="form.activity"
                         :options="form.options2">
                         </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="mt-15">
                        <b-col>
                        <b-form-select class="request-experiment-input" v-model="form.employees_num"
                          :options="form.options3">
                         </b-form-select>
                        </b-col>
                         <b-col>
                        <b-form-select class="request-experiment-input" v-model="form.hear_about_us"
                          :options="form.options4">
                         </b-form-select>
                        </b-col>
                    </b-row>
                     <b-row class="mt-15">
                        <b-col>
                        <b-form-select class="request-experiment-input" v-model="form.do_you_have_accountant"
                          :options="form.options5">
                         </b-form-select>
                        </b-col>
                         <b-col>
                             
                        </b-col>
                    </b-row>
                    <b-row class="mt-15">
                        <b-col>
                            <span class="request-experiment-span">{{$t('request_experiment_form.type_need')}}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                          <b-col>
                              <div>
                                  <b-form-group class="chk-div">
                                 <b-form-checkbox-group  v-model="form.chks">
                                       <b-form-checkbox  value="1">{{$t('request_experiment_form.financial_accounting')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="2">{{$t('request_experiment_form.customer_relationship_management')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="3">{{$t('request_experiment_form.electronic_trade')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="4">{{$t('request_experiment_form.manage_company_resources')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="5">{{$t('request_experiment_form.human_resources_personal')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="6">{{$t('request_experiment_form.manage_requests')}}</b-form-checkbox><br>
                                      <b-form-checkbox value="7">{{$t('request_experiment_form.pos')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="8">{{$t('request_experiment_form.automation_professional_services')}}</b-form-checkbox><br>
                                      <b-form-checkbox  value="9">{{$t('request_experiment_form.vat')}}</b-form-checkbox><br>
                                      <b-form-checkbox   value="10">{{$t('request_experiment_form.inventory_warehouse_management')}}</b-form-checkbox><br>
                                       <b-form-checkbox  value="11">{{$t('request_experiment_form.connect_branches')}}</b-form-checkbox><br>
                                        <b-form-checkbox  value="12">{{$t('request_experiment_form.billing_sales_management')}}</b-form-checkbox><br>
                                         <b-form-checkbox  value="13">{{$t('request_experiment_form.selling_points')}}</b-form-checkbox><br>
                                 </b-form-checkbox-group>
                                  </b-form-group>
                              </div>
                             
                               
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span class="request-experiment-span">{{$t('request_experiment_form.ucy.label')}}</span><br>
                            <span class="request-experiment-span">{{$t('request_experiment_form.ucy.value')}}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                >
                                <b-form-input v-model="form.uyc"
                                    class="request-experiment-input"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row align-h="center">
                        <b-col cols="4">
                            <b-button @click="onSubmit"  class="try-btn w-320 pos-l-100">
                                {{$t('request_experiment_form.send')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-row>
        </b-container>
  </section>
</template>

<script>
export default {
data() {
      return {
        form: {
          chks:["1","2","4"],
          name: '',
          company:'',
          email:'',
          phone:'',
          position:null,
          uyc:'',
          activity:null,
          employees_num:null,
          hear_about_us:null,
          do_you_have_accountant:null,
        options: [
          { value: null, text: this.$t('request_experiment_form.position'),disabled: true  },
          { value: 'a', text: 'قيمة1'},
        { value: 'b', text: 'قيمة2'}
        ],
        options2: [
          { value: null, text: this.$t('request_experiment_form.activity_type'),disabled: true  },
          { value: 'a', text: 'قيمة1'},
        { value: 'b', text: 'قيمة2'}
        ],
        options3: [
          { value: null, text: this.$t('request_experiment_form.employees_num'),disabled: true  },
          { value: 'a', text: 'قيمة1'},
          { value: 'b', text: 'قيمة2'}
        ],
        options4: [
          { value: null, text: this.$t('request_experiment_form.how_did_you_hear'),disabled: true  },
          { value: 'a', text: 'الانترنت'},
          { value: 'b', text: 'التلفاز'},
          { value: 'b', text: 'المذياع'}
        ],
        options5: [
          { value: null, text: this.$t('request_experiment_form.do_you_have_accountant'),disabled: true  },
          { value: 'y', text: 'نعم'},
          { value: 'n', text: 'لا'}
        ]
        },
      }
    },
    methods: {
      onSubmit() {
          console.log(this.form);
      },
    }
}
</script>

<style>

</style>