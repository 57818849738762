<template>
    <section id="video-player">
        <div class="part-circle">
          <img src="/assets/landing/images/about/video-player/part-circle.svg" alt="">
        </div>
        <b-container>
          <b-row>
          <div  class="video-player2 center-block mt-25 t-r">
               <h2 class="purble-clr fs-24 text-center"> {{$t('reports_page.video_player2.header')}}</h2>
               <p class="pad-t-15 t-c w-800">{{$t('reports_page.video_player2.sub_header')}}</p>
              <iframe class="mt-30 mr-100" width="600" height="315"
              src="https://www.youtube.com/embed/ZrtVRGbN4I8">
              </iframe>
          </div>
          </b-row>
        </b-container>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>