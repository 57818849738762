<template>
  <section id="our-clients">
       <div id="our-client">
        <h2>{{ourClients.title}}</h2>
        </div>
        <!-- slider -->
           <b-container>
            <div class="owl-carousel">
    <div v-for="(c,key) in ourClients.items" :key="key">
        <img :src="c.image" alt="">
    </div>
</div>
    <i @click="next()" class="fa fa-arrow-left next-carousel"></i>
    <i @click="prev()" class="fa fa-arrow-right prev-carousel"></i>
        </b-container>
  </section>
</template>

<script>
    
export default {
 props:["ourClients"],
    data(){
        return {}
    },
    methods:{
        prev(){
            $('.owl-carousel').trigger('prev.owl.carousel');
        },
        next(){
            $('.owl-carousel').trigger('next.owl.carousel');
        }
    },
    mounted(){
         let dir = false
    let landing_lang = localStorage.getItem('landing_lang');
    if(landing_lang === 'ar') {
        dir = true
    }
    setTimeout(() => {
        $('.owl-carousel').owlCarousel({
    rtl:dir,
    loop:true,
    margin:20,
    stagePadding: 50,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }
})
    }, 1000);
    }
}
</script>

<style>

</style>