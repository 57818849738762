<template>
    <section id="video-player">
        <div class="part-circle">
          <img src="/assets/landing/images/about/video-player/part-circle.svg" alt="">
        </div>
        <b-container>
          <b-row>
        <div class="center-block">
          <img src="/assets/landing/images/about/video-player/logo.svg" alt="">
        </div>
          </b-row>
          <b-row>
          <div  class="center-block mt-25 t-r">
               <h2 class="blue-clr fs-24 text-center">{{$t('about_page.video_player.header')}}</h2>
               <p>{{$t('about_page.video_player.sub_header')}}</p>
              <iframe width="600" height="315"
              src="https://www.youtube.com/embed/ZrtVRGbN4I8">
              </iframe>
          </div>
          </b-row>
        </b-container>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>