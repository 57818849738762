<template>
    <section id="reports2">
            <b-container>
        <b-row  class="t-r section">
        <b-col v-for="(report,key) in reports" :key="key" lg="6" md="12" sm="12">
        <div>
        <img width="120" :src="report.icon" alt="" title="" />
        <div class="report-block">
        <p class="blue-clr">{{report.title}}</p>
        <b-button  class="w-140 try-btn">{{$t('reports_page.reports2.footer')}}</b-button>
         </div>
        </div>
        </b-col>
        </b-row>
            </b-container>
    </section>
</template>

<script>
export default {
    data(){ 
        return {
            reports: [
                {
                    id:1,
                    title:this.$t('reports2.a.title'),
                    icon:"/assets/landing/images/reports-page/reports2/1.svg"
                },
                {
                    id:2,
                    title:this.$t('reports2.b.title'),
                    icon:"/assets/landing/images/reports-page/reports2/2.svg"
                },
            ]
        }
    }
}
</script>

<style>

</style>