<template>
 <section id="tour2-boxes">
        <div class="tour2-boxes">
            <b-container>
                  <b-row>
    <b-col  v-for="(feature,key) in features" :key="key" lg="4" md="6" sm="12">
        <div class="t-r mt-30 tour2_box">
        <img  class="p-feature-icon border-r-50per pad-15"
         :src="feature.icon" alt="" title="" />
         <h5 class="pad-t-15 blue-clr">{{feature.title}}</h5>
         <p class="pad-t-5">{{feature.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
  </section>
</template>

<script>
export default {
    data(){
        return {
            features: [
                {
                    id:1,
                    title:this.$t('tour2.legal'),
                    details: this.$t('tour2.legal_details'),
                    icon:"/assets/landing/images/features-page/tour2/map-lawyer.svg"
                },
                  {
                    id:2,
                      title:this.$t('tour2.interface'),
                      details: this.$t('tour2.interface_details'),
                      icon:"/assets/landing/images/features-page/tour2/metro-language.svg"
                },
                 {
                    id:3,
                     title:this.$t('tour2.analysis'),
                     details: this.$t('tour2.analysis_details'),
                     icon:"/assets/landing/images/features-page/tour2/file-import.svg"
                },

                {
                    id:4,
                    title:this.$t('tour2.notifications'),
                    details: this.$t('tour2.notifications_details'),
                    icon:"/assets/landing/images/features-page/tour2/notifications.svg"
                },

                {
                    id:5,
                    title:this.$t('tour2.reports'),
                    details: this.$t('tour2.reports_details'),
                    icon:"/assets/landing/images/features-page/tour2/file-alt.svg"
                },

            ]
        }
    }
}
</script>

<style>

</style>
