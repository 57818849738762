<template>
    <section class="report-container">
        <b-container>
            <b-row align-h="center">
                <strong class="report-title">{{reports.title}}</strong>
            </b-row>
            <b-row align-h="center">
                <span class="report-description">{{reports.sub_title}}</span>
            </b-row>
            <b-row class="report-data t-r">
                <b-col v-for="(rep,index) in reports.items" :key="index">
                    <div>
                        <div><img :src="rep.image"></div>
                        <div class="sub-report-title">{{rep.title}}</div>
                        <div class="sub-report-description">{{rep.description}}</div>
                        <div @click="goTo(rep.btn.target)"><a class="sub-report-more">{{rep.btn.title}}</a></div>
                    </div>
                </b-col>
            </b-row>
            <div class="report-paginate">
                <b-row align-h="center" class="grey-clr">
                    <div class="h2 mb-0 slider-btn">
                        <b-icon v-if="lang=='en'" icon="arrow-left-short" class="arrow-icon"></b-icon>
                        <b-icon v-else icon="arrow-right-short" class="arrow-icon"></b-icon>
                    </div>
                    <div>
                        <span class="slider-dot active"></span>
                        <span class="slider-dot"></span>
                        <span class="slider-dot"></span>
                    </div>
                    <div class="h2 mb-0 slider-btn">
  <b-icon v-if="lang=='en'" icon="arrow-right-short" class="arrow-icon"></b-icon>
  <b-icon v-else icon="arrow-left-short" class="arrow-icon"></b-icon>
                      </div>
                </b-row>
            </div>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "Report",
        props:["reports"],
          computed:{
    lang(){return localStorage.getItem('landing_lang')
    },
},
  methods:{
         goTo(page) {
            this.$router.push(page);
        }
    }
    }
</script>

<style>
</style>
