<template>
    <section id="screen">
        <div  class="screen-grid">
         <img src="/assets/landing/images/about/screen/grid.svg" alt="">
        </div>
        <b-container class="pos-b-150">
          <b-row>
        <b-col lg="6">
            <div class="f-r">
             <img class="mt-70" width="100%" src="/assets/landing/images/about/screen/screen.svg" alt="">
            </div>
        </b-col>
         <b-col lg="6">
        <div class="t-r mt-120 w-430">
             <p>{{$t('about_page.screen.header')}}</p>
         <b-button class="try-btn w-140">{{$t('about_page.screen.footer')}}</b-button>
        </div>
         </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>