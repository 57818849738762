<template>
    <div class="bundle-section mt-50">
    <b-container>
    <table class="bundle-table">
    <tr>
    <th class="top1 th1">{{$t('bunches_details.header1.col1')}}</th>
    <th class="top1">
        <p>{{$t('bunches_details.header1.col2-1')}}</p>
        <p>{{$t('bunches_details.header1.col2-2')}}</p>
        </th>
    <th class="top1">
        <p>{{$t('bunches_details.header1.col3-1')}}</p>
        <p>{{$t('bunches_details.header1.col3-2')}}</p>
    </th>
    <th class="top1">
        <p>{{$t('bunches_details.header1.col4-1')}}</p>
        <p>{{$t('bunches_details.header1.col4-2')}}</p>
    </th>
  </tr>
  <tr>
    <th class="top2 th2">{{$t('bunches_details.header2.col1')}}</th>
    <th class="top2">{{$t('bunches_details.header2.col2')}}</th>
    <th class="top2 dir-ltr">{{$t('bunches_details.header2.col3')}}</th>
    <th class="top2 dir-ltr">{{$t('bunches_details.header2.col4')}}</th>
  </tr>

  <br>
  <tr>
    <th colspan="4" class="top3 t-c">{{$t('bunches_details.details.title')}}</th>
  </tr>
  <tr class="details" v-for="(det,index) in details" :key="(index)">
    <td class="dtd">{{det.title}}</td>
    <td><div class="circle center-block" :class="det.col2=='×'?'brown-clr':'green-clr'">{{det.col2}}</div></td>
    <td><div class="circle center-block" :class="det.col3=='×'?'brown-clr':'green-clr'">{{det.col3}}</div></td>
    <td><div class="circle center-block" :class="det.col4=='×'?'brown-clr':'green-clr'">{{det.col4}}</div></td>
  </tr>

  <br>
  <tr>
    <th colspan="4" class="top3 t-c">{{$t('bunches_details.details.title')}}</th>
  </tr>
  <tr class="details" v-for="(det,index) in details" :key="(index)">
    <td class="dtd">{{det.title}}</td>
    <td><div class="circle center-block" :class="det.col2=='×'?'brown-clr':'green-clr'">{{det.col2}}</div></td>
    <td><div class="circle center-block" :class="det.col3=='×'?'brown-clr':'green-clr'">{{det.col3}}</div></td>
    <td><div class="circle center-block" :class="det.col4=='×'?'brown-clr':'green-clr'">{{det.col4}}</div></td>
  </tr>


  <br>
  <tr>
    <th colspan="4" class="top3 t-c">{{$t('bunches_details.details.title')}}</th>
  </tr>
  <tr class="details" v-for="(det,index) in details" :key="(index)">
    <td class="dtd">{{det.title}}</td>
    <td><div class="circle center-block" :class="det.col2=='×'?'brown-clr':'green-clr'">{{det.col2}}</div></td>
    <td><div class="circle center-block" :class="det.col3=='×'?'brown-clr':'green-clr'">{{det.col3}}</div></td>
    <td><div class="circle center-block" :class="det.col4=='×'?'brown-clr':'green-clr'">{{det.col4}}</div></td>
  </tr>

  <br>
  <tr>
    <th colspan="4" class="top3 t-c">{{$t('bunches_details.details.title')}}</th>
  </tr>
  <tr class="details" v-for="(det,index) in details" :key="(index)">
    <td class="dtd">{{det.title}}</td>
    <td><div class="circle center-block" :class="det.col2=='×'?'brown-clr':'green-clr'">{{det.col2}}</div></td>
    <td><div class="circle center-block" :class="det.col3=='×'?'brown-clr':'green-clr'">{{det.col3}}</div></td>
    <td><div class="circle center-block" :class="det.col4=='×'?'brown-clr':'green-clr'">{{det.col4}}</div></td>
  </tr>
  
  
      </table>
      </b-container>
    </div>
</template>

<script>
export default {
data(){
    return {
        details:[
            {
                id:1,
                title:this.$t('bunches_details.details.row1'),
                col2:'×',
                col3:'✓',
                col4:'✓'
            },
            {
                id:2,
                title:this.$t('bunches_details.details.row2'),
                col2:'×',
                col3:'✓',
                col4:'✓'
            },
            {
                id:3,
                title:this.$t('bunches_details.details.row3'),
                col2:'×',
                col3:'✓',
                col4:'✓'
            },
             {
                id:4,
                title:this.$t('bunches_details.details.row4'),
                col2:'×',
                col3:'✓',
                col4:'✓'
            },
             {
                id:5,
                title:this.$t('bunches_details.details.row5'),
                col2:'×',
                col3:'✓',
                col4:'✓'
            },
             {
                id:6,
                title:this.$t('bunches_details.details.row6'),
                col2:'✓',
                col3:'✓',
                col4:'✓'
            },
             {
                id:7,
                title:this.$t('bunches_details.details.row7'),
                col2:'✓',
                col3:'✓',
                col4:'✓'
            }
        ]
    }
}
}
</script>

<style>

</style>