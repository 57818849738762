<template>
  <div>
    <hero></hero>
    <features2></features2>
    <features3></features3>
    <features4></features4>
    <tour2></tour2>
    <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>