import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

let landing_lang  = localStorage.getItem('landing_lang');
let defaultLang = 'en';
if (landing_lang  != null) {
    defaultLang = landing_lang ;
} else {
    localStorage.setItem('landing_lang', defaultLang)
}
if(landing_lang == 'en') {
    require('/assets/landing/css/style-en.css');
}
document.getElementsByTagName('html')[0].setAttribute('dir', defaultLang == 'en' ? 'ltr' : 'rtl')
const DEFAULT_LANG = defaultLang; //library default lang
const locales = {
    ar: require("./i18n/ar.json"),
    en: require("./i18n/en.json"),
};
const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages: locales,
});

export default i18n;
