<template>
    <div class="bottom-footer-container">
        <b-container>
        <b-row>
                <div class="payments-methods f-r">
                    <img width="80" class="pad-l-15" src="/assets/landing/images/footer/bottom-footer/paypal.svg"/>
                    <img width="80" class="pad-l-15"   src="/assets/landing/images/footer/bottom-footer/mastercard.svg"/>
                    <img width="80" class="pad-l-15"  src="/assets/landing/images/footer/bottom-footer/visa.svg"/>
                </div>
                <div class="social-media f-l">
                <i class="fa fa-envelope purble-clr pad-l-15"></i>
                <i class="fa fa-linkedin purble-clr pad-l-15"></i>
                <i class="fa fa-instagram purble-clr pad-l-15"></i>
                <i class="fa fa-twitter purble-clr pad-l-15"></i>
                <i class="fa fa-facebook purble-clr pad-l-15"></i>
                </div>
        </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "BottomFooter"
    }
</script>

<style>

</style>
