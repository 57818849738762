<template>
 <section id="privacy-policy">
      <div class="circle">
          <img src="/assets\landing\images\about\video-player\part-circle.svg" alt="">
        </div>
        <div class="container">
           <div class="intro pad-r-50">
        
                <div class="intro1">
                        
               <p class="purble-clr">{{$t('privacy_policy_page.intro.title')}}</p>
             <p class="grey-dark-clr">
                     {{$t('privacy_policy_page.intro.details')}}
             </p>
                </div>

             <br>
                <div class="def">
                <p class="purble-clr">
                {{$t('privacy_policy_page.definitions')}}
                </p>
                <p v-for="(def,index) in definitions" :key="index" class="grey-dark-clr">
                                <span  class="grey-light-clr">{{def.title}}</span>
                        {{def.details}}
                </p>
                </div>
              
               
           </div>

        </div>
  </section>
</template>

<script>
export default {
    data(){
        return {
        definitions: 
        [
        {
        title:this.$t('privacy_policy.a.title'),
        details:this.$t('privacy_policy.a.details')
        },
        {
        title:this.$t('privacy_policy.b.title'),
        details:this.$t('privacy_policy.b.details')
        },
        {
        title:this.$t('privacy_policy.c.title'),
        details:this.$t('privacy_policy.c.details')
        },
        {
        title:this.$t('privacy_policy.d.title'),
        details:this.$t('privacy_policy.d.details')
        },
        {
          title:this.$t('privacy_policy.e.title'),
          details:this.$t('privacy_policy.e.details')
        },
        {
        title:this.$t('privacy_policy.f.title'),
        details:this.$t('privacy_policy.f.details')
        },
        {
        title:this.$t('privacy_policy.g.title'),
        details:this.$t('privacy_policy.g.details')
        },
        {
        title:this.$t('privacy_policy.h.title'),
        details:this.$t('privacy_policy.h.details')
        }
        ]
        }
    }
}
</script>

<style>

</style>
