<template>
    <section class="contact-us-container">
        <b-container>
            <b-row>
                <b-col>
                    <div class="contact-title">
                        <h2>{{ $t('contact_us.header')}}</h2>
                    </div>
                    <div class="contact-description">
                        <p>{{$t('contact_us.description')}}</p>
                    </div>
                    <b-form class="contact-form">
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="input-group-1"
                                    label=""
                                    label-for="input-1"
                                    description=""
                                >
                                    <b-form-input
                                        id="input-1"
                                        type="email"
                                        required
                                        :placeholder="$t('contact_us.name')"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group
                                    id="input-group-2"
                                    label=""
                                    label-for="input-1"
                                    description=""
                                >
                                    <b-form-input
                                        id="input-1"
                                        type="email"
                                        required
                                        :placeholder="$t('contact_us.email')"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="input-group-3"
                                    label=""
                                    label-for="input-1"
                                    description=""
                                >
                                    <b-form-textarea
                                        id="textarea"
                                        :placeholder="$t('contact_us.details')"
                                        rows="5"
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button type="submit"  class="try-btn w-140 f-l" >{{$t('contact_us.send')}}</b-button>
                    </b-form>
                </b-col>
                <b-col>
                    <img class="contact-img f-l" src="/assets/landing/images/contact/contact.svg" alt="تواصل معنا">
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>

<style scoped>

</style>
