import Vue from "vue";

import Home from "./views/Home";

import Hero from "./components/hero/Index";
import NavBar from "./components/hero/NavBar";
import HeadBar from "./components/hero/HeadBar";

import Programs from "./components/sections/programs/Index";

import Features from "./components/sections/features/Index";

import Reports from "./components/sections/reports/Index";

import OurClients from "./components/sections/our-clients/Index";

import SaidAboutUs from "./components/sections/said-about-us/Index";
import Videos from "./components/sections/said-about-us/Videos";

import AboutUs from "./components/sections/about-us/Index";

import WhatIsIt from "./components/sections/what-is-it/Index";

import ContactUs from "./components/sections/contact-us/Index";

import LFooter from "./components/footer/Index";
import BottomFooter from "./components/footer/BottomFooter";
import TopFooter from "./components/footer/TopFooter";
import FixedFooter from "./components/footer/FixedFooter";

import About from "./views/About";
import VideoPlayer from "./components/sections/video-player/Index";
import Screen from "./components/sections/screen/Index";
import Tour from "./components/sections/tour/Index";
import TourBoxes from "./components/sections/tour/TourBoxes";
import FeaturesInline from "./components/sections/features-inline/Index";

import FeaturesPage from "./views/Features";
import Features2 from "./components/sections/features2/Index";
import Features3 from "./components/sections/features3/Index";
import Features4 from "./components/sections/features4/Index";
import Tour2 from "./components/sections/tour2/Index";
import Tour2Boxes from "./components/sections/tour2/Tour2Boxes";

import ProgramsPage from "./views/Programs";
import Programs2 from "./components/sections/programs2/Index";

import MultiReports from "./components/sections/multi-reports/Index";
import ExtraFeatures from "./components/sections/extra-features/Index";
import Programs3 from "./components/sections/programs3/Index";
import Tour3 from "./components/sections/tour3/Index";

import SaidAboutUs2 from "./components/sections/said-about-us2/Index";
import Videos2 from "./components/sections/said-about-us2/Videos2";

import Analytics from "./components/sections/analytics/Index";
import VideoPlayer2 from "./components/sections/video-player2/Index";
import Tour4 from "./components/sections/tour4/Index";
import Reports2 from "./components/sections/reports2/Index";

import UserManualDetails from './components/sections/user-manual-details/index'
import UserManual from "./components/sections/user-manual/Index";
import PrivacyPolicy from "./components/sections/privacy-policy/Index";
import Tour5 from "./components/sections/tour5/Index";
import IBunches from './components/sections/bunches/index';

import BunchesDetails from "./components/sections/bunches-details/Index";

import ISupport from './components/sections/support/index'
import Support from "./views/Support";
import Faq from "./components/sections/Faq/Index";
import RequestExperiment from "./components/sections/request-experiment/Index";


Vue.component("Home", Home);

Vue.component("Hero", Hero);
Vue.component("NavBar", NavBar);
Vue.component("HeadBar", HeadBar);
Vue.component("Programs", Programs);
Vue.component("Features", Features);
Vue.component("Reports", Reports);
Vue.component("OurClients", OurClients);
Vue.component("SaidAboutUs", SaidAboutUs);
Vue.component("Videos", Videos);
Vue.component("AboutUs", AboutUs);
Vue.component("WhatIsIt", WhatIsIt);
Vue.component("ContactUs", ContactUs);
Vue.component("BottomFooter", BottomFooter);
Vue.component("TopFooter", TopFooter);
Vue.component("FixedFooter", FixedFooter);
Vue.component("LFooter", LFooter);

Vue.component("About", About);
Vue.component("VideoPlayer", VideoPlayer);
Vue.component("Screen", Screen);
Vue.component("Tour", Tour);
Vue.component("TourBoxes", TourBoxes);
Vue.component("FeaturesInline", FeaturesInline);

Vue.component("FeaturesPage", FeaturesPage);
Vue.component("Features2", Features2);
Vue.component("Features3", Features3);
Vue.component("Features4", Features4);
Vue.component("Tour2", Tour2);
Vue.component("Tour2Boxes", Tour2Boxes);

Vue.component("ProgramsPage", ProgramsPage);
Vue.component("Programs2", Programs2);


Vue.component("MultiReports", MultiReports);
Vue.component("ExtraFeatures", ExtraFeatures);
Vue.component("Programs3", Programs3);
Vue.component("Tour3", Tour3);

Vue.component("SaidAboutUs2", SaidAboutUs2);
Vue.component("Videos2", Videos2);

Vue.component("Analytics", Analytics);
Vue.component("VideoPlayer2", VideoPlayer2);
Vue.component("Tour4", Tour4);
Vue.component("Reports2", Reports2);
Vue.component("VUserManualDetails",UserManualDetails);

Vue.component("UserManual", UserManual);
Vue.component("PrivacyPolicy", PrivacyPolicy);
Vue.component("Tour5", Tour5);
Vue.component('IBunches',IBunches);

Vue.component("BunchesDetails", BunchesDetails);
Vue.component('ISupport', ISupport)
Vue.component('Support', Support)

Vue.component("Faq", Faq);

Vue.component("RequestExperiment", RequestExperiment);



