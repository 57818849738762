<template>
      <section id="tour3">
     <div class="tour3-circle">
          <img src="/assets/landing/images/features-page/tour2/circle.svg" alt="">
     </div>
      <div class="pos-l-350 tour4">
          <img width="200" class="f-r" src="/assets/landing/images/reports-page/tour4/report.svg" alt="">
         </div>
    <b-container>
         <div class="t-r pos-l-200 tour4_section">
         <p  class="orange-clr text-center pad-l-150 fs-22 header">{{$t('reports_page.tour4.header')}}</p>
         <p class="white-clr w-1000 text-center fs-16 sub_header">{{$t('reports_page.tour4.sub_header')}}</p>
    </div> 
        </b-container>          
</section>
</template>

<script>
export default {
}
</script>

<style>

</style>