<template>
    <section id="features2">
        <div class="part-circle">
          <img src="/assets/landing/images/features-page/features2/part-circle.svg" alt="">
        </div>
        <div class="circle">
          <img src="/assets/landing/images/features-page/features2/circle.svg" alt="">
        </div>
          <div id="feature-boxes">
            <b-container>
        <b-row v-for="(feature,key) in features" :key="key" lg="12" md="12" sm="12" class="t-r mt-30">
        <b-col lg="3" md="6" sm="12">
        <img :src="feature.icon" alt="" title="" />
        </b-col>
        <b-col lg="9" md="6" sm="12" class="">
            <span class="fs-20 blue-clr">{{feature.title}}</span>
            <p class="pad-t-5 grey-light-clr">{{feature.details}}</p>
        </b-col>
        </b-row>
            </b-container>
        </div>
    </section>
</template>

<script>
export default {
    data(){ 
        return {
            features: [
                {
                    id:1,
                    title:this.$t('features2.commentAndTags.title'),
                    details:this.$t('features2.commentAndTags.details'),
                    icon:"/assets/landing/images/features-page/features2/1.svg"
                },
                 {
                    id:2,
                    title:this.$t('features2.attributionAndAssignment.title'),
                    details:this.$t('features2.attributionAndAssignment.details'),
                    icon:"/assets/landing/images/features-page/features2/2.svg"
                },
                 {
                    id:3,
                    title:this.$t('features2.workflowEngine.title'),
                    details:this.$t('features2.workflowEngine.details'),
                    icon:"/assets/landing/images/features-page/features2/3.svg"
                },
                 {
                    id:4,
                    title:this.$t('features2.electronicArchive.title'),
                    details:this.$t('features2.electronicArchive.details'),
                    icon:"/assets/landing/images/features-page/features2/4.svg"
                },
            ]
        }
    }
}
</script>

<style>

</style>