<template>
 <div>
      <section id="said-about-us">
        <div id="said-about-us-header">
        <img class="bubble" src="/assets/landing/images/said-about-us/chat-bubble-outline.svg" alt="">
        <h2>{{saidAboutUs.title}}</h2>
 <img class="repeat-grid-about-us" src="/assets/landing/images/said-about-us/repeat-grid.svg" alt="">
 <img class="bubble2" src="/assets/landing/images/said-about-us/chat-bubble-outline2.svg" alt="">  
  </div>
              <b-container>
                  <b-row>
        <b-col v-for="(customer,key) in saidAboutUs.items" :key="key" lg="4" md="12" sm="12"
        v-if="customer.description">
        <div>
            <div class="customer t-r">
            <div class="customer-block">
            <img class="customer-img" :src="customer.image" alt="">
             <div class="customer-details">
             <p  class="customer-name">{{customer.title}}</p>
             <p   class="customer-job">{{customer.sub_title}}</p>

           <li class="clearfix">
            <div class="message me-message f-r">{{customer.description}}</div>
          </li>
             </div>
            </div>            
        </div>
        </div>
    </b-col>
        </b-row>
              </b-container>
  </section>

    <videos :videos="saidAboutUs"></videos>
 </div> 
</template>

<script>
export default {
     props:["saidAboutUs"],
data(){
    return {}
}
}
</script>

<style>
</style>