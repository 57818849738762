<template>
<section>
     <div id="tour-boxes">
            <b-container>
                  <b-row>
    <b-col v-for="(tour,key) in tours" :key="key" lg="4" md="6" sm="12">
        <div class="text-center">
        <img  class="pad-t-30 mx-auto" width="300"
         :src="tour.icon" alt="" title="" />
         <h5 class="pad-t-15 grey-light-clr">{{tour.title}}</h5>
         <p class="grey-dark-clr">{{tour.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
</section>
</template>

<script>
export default {
    data(){ 
        return {
            tours: [
                {
                    id:1,
                    title:this.$t('tours.t1.title'),
                    details:this.$t('tours.t1.details'),
                    icon:"/assets/landing/images/about/tour/1.svg"
                },
                 {
                    id:2,
                    title:this.$t('tours.t2.title'),
                    details:this.$t('tours.t2.details'),
                    icon:"/assets/landing/images/about/tour/2.svg"
                },
                 {
                    id:3,
                    title:this.$t('tours.t3.title'),
                    details:this.$t('tours.t3.details'),
                    icon:"/assets/landing/images/about/tour/3.svg"
                },
                 {
                    id:4,
                    title:this.$t('tours.t4.title'),
                    details:this.$t('tours.t4.details'),
                    icon:"/assets/landing/images/about/tour/4.svg"
                },
                 {
                    id:5,
                    title:this.$t('tours.t5.title'),
                    details:this.$t('tours.t5.details'),
                    icon:"/assets/landing/images/about/tour/5.svg"
                },
                 {
                    id:6,
                    title:this.$t('tours.t6.title'),
                    details:this.$t('tours.t6.details'),
                    icon:"/assets/landing/images/about/tour/6.svg"
                },
                 {
                    id:7,
                    title:this.$t('tours.t7.title'),
                    details:this.$t('tours.t7.details'),
                    icon:"/assets/landing/images/about/tour/7.svg"
                },
                 {
                    id:8,
                    title:this.$t('tours.t8.title'),
                    details:this.$t('tours.t8.details'),
                    icon:"/assets/landing/images/about/tour/8.svg"
                },
                 {
                    id:9,
                    title:this.$t('tours.t9.title'),
                    details:this.$t('tours.t9.details'),
                    icon:"/assets/landing/images/about/tour/9.svg"
                },
            ]
        }
    }
}
</script>

<style>

</style>
