<template>
  <section id="videos">
         <b-container>
                  <b-row>
          <b-col v-for="(video,key) in videos" :key="key" lg="4" md="6" sm="12">
            <img :src="video.img" class="mt-30" width="300" alt="">
            <i class="fa fa-youtube-play youtube-video"></i>
            <div class="video-details">
             <p class="video-name">{{video.name }}</p>
             <p class="video-job">{{video.job}}</p>
             </div>
          </b-col>
            </b-row>
         </b-container>
    </section>
</template>

<script>
export default {
data(){
    return {
        
        videos: [
            {
                id:1,
                img:"/assets/landing/images/said-about-us/videos/1.svg",
                name:this.$t('videos2.v1.name'),
                job:this.$t('videos2.v1.job'),
            },
            {
                id:2,
                img:"/assets/landing/images/said-about-us/videos/2.svg",
                name:this.$t('videos2.v2.name'),
                job:this.$t('videos2.v2.job'),
            },
            {
                id:3,
                img:"/assets/landing/images/said-about-us/videos/3.svg",
                name:this.$t('videos2.v3.name'),
                job:this.$t('videos2.v3.job'),
            },
            {
                id:4,
                img:"/assets/landing/images/said-about-us/videos/1.svg",
                name:this.$t('videos2.v4.name'),
                job:this.$t('videos2.v4.job'),
            },
            {
                id:5,
                img:"/assets/landing/images/said-about-us/videos/2.svg",
                name:this.$t('videos2.v5.name'),
                job:this.$t('videos2.v5.job'),
            },
            {
                id:6,
                img:"/assets/landing/images/said-about-us/videos/3.svg",
                name:this.$t('videos2.v6.name'),
                job:this.$t('videos2.v6.job'),
            }
            
        ]
    }
}
}
</script>

<style>
</style>