<template>
  <section id="faq" class="t-r">
      <b-container>
          <div>
  <b-card no-body>
    <b-tabs card>
      <b-tab :title="$t('faq.all')">
            
        <div v-for="(item,index) in list" :key="index">
            <div class="mt-15">
  <b-button  v-b-toggle="'collapse-' + item.id"  variant="primary" class="bg-border-blue">
      <span v-if="item.visible">-</span>
      <span v-else>+
      </span>
  </b-button>
  <span>{{item.title}}</span>
  <b-collapse :id="'collapse-' + item.id" :visible="item.visible" v-model="item.visible" class="mt-2">
      <p class="collapse-inner">
          {{item.details}}
      </p>
  </b-collapse>
</div>
        </div>

      </b-tab>
          <b-tab :title="$t('faq.technical_questions')">
              {{$t('faq.technical_questions')}}
          </b-tab>
          <b-tab :title="$t('faq.packages_and_prices')">
              {{$t('faq.packages_and_prices')}}
          </b-tab>
          <b-tab :title="$t('faq.support')">
              {{$t('faq.support')}}
          </b-tab>
          <b-tab :title="$t('faq.payment_methods')">
              {{$t('faq.payment_methods')}}
          </b-tab>

    </b-tabs>
  </b-card>
</div>
      </b-container>
      
  </section>
</template>

<script>
export default {
data(){
    return {
        list:[
            {id:1,
            visible:true,
            title:this.$t('faq.data.title'),
            details:this.$t('faq.data.details'),
            },
             {id:2,
            visible:false,
            title:this.$t('faq.data.title'),
            details:this.$t('faq.data.details'),
             },
            {id:3,
            visible:false,
            title:this.$t('faq.data.title'),
            details:this.$t('faq.data.details'),
            },
             {id:4,
            visible:false,
            title:this.$t('faq.data.title'),
            details:this.$t('faq.data.details'),
             },
             {id:5,
            visible:false,
           title:this.$t('faq.data.title'),
            details:this.$t('faq.data.details'),
             }
        ]
    }
}
}
</script>

<style>

</style>