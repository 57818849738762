<template>
    <section class="about-us-container mt-100">
        <div> 
            <img class="f-l part-circle" src="/assets/landing/images/about-us/part-circle.svg" alt="about us">
        </div>
        <b-container>
            <b-row class="analytics-row t-r">
                <b-col class="analytics-col">
                    <div class="analytics-details">
                            <p class="about-title">{{$t('reports_page.analytics.header')}}</p>
                            <p class="about-content">{{$t('reports_page.analytics.sub_header')}}</p>
                    </div>
                </b-col>
                 <b-col cols="4" class="analytic_img pos-l-150 pos-t-50">
                    <img src="/assets/landing/images/reports-page/analytics/analytics.svg" alt="about us">
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "AboutUs"
    }
</script>

<style>
</style>
