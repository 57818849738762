<template>
    <section class="what-container">
        <div>
         <img class="f-r mt-50" src="/assets/landing/images/what-is-it/repeat-grid.svg" alt="logo" />
        </div>
        <b-container>
            <b-row class="what-is-row">
                <b-col lg="3"  class="what-is-col">
                    <div class="what-is-right">
                        <img width="30" src="/assets/landing/images/what-is-it/circle.svg" alt="logo" />
                        <span class="what-is">{{whatIsIt.title}}</span>
                        <img class="mt-25" height="40px" src="/assets/landing/images/what-is-it/logo.svg" alt="logo" />
                        <p class="what-title">{{whatIsIt.sub_title}}</p>
                        <p class="what-sub-title">{{whatIsIt.description}} </p>
                        <p class="hello-aptus">{{$t('what_is_it.footer')}}</p>
                    </div>
                </b-col>
                <b-col lg="9">
                    <b-row>
                          <b-col  v-for="(w,key) in whatIsIt.items" :key="key" lg="6" md="4" sm="6" class="what-nav-box">
                            <ul  class="what-nav list-style-none">
                                <li><img :id="w.icon" :src="w.image" class="what-img"></li>
                                <li  class="what-card-title">{{w.title}}</li>
                                <li class="what-card-description">{{w.description}}</li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "WhatIsIt",
          props:["whatIsIt"],
    }
</script>

<style>    
</style>
