<template>
    <div class="fixed-footer-container">
        <p>{{ $t('footer.all_rights_reserved') }} @2020</p>
    </div>
</template>

<script>
    export default {
        name: "FixedFooter"
    }
</script>

<style>

</style>
