<template>
    <section id="features4">
        <div class="part-circle">
          <img src="/assets/landing/images/features-page/features4/part-circle.svg" alt="">
        </div>
        <div class="circle">
          <img src="/assets/landing/images/features-page/features4/circle.svg" alt="">
        </div>
            <div>
            <b-container>
                  <b-row>
    <b-col v-for="(feature,key) in features" :key="key" lg="6" md="6" sm="12">
        <div class="f-r text-center">
        <img  class="mx-auto mt-30"
         :src="feature.icon" alt="" title="" />
         <h5  class="mt-15">{{feature.title}}</h5>
         <p  class="mt-15 w-420">{{feature.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
    </section>
</template>

<script>
export default {
    data(){ 
        return {
            features: [
                {
                    id:1,
                    title:this.$t('features4.otp.title'),
                    details:this.$t('features4.otp.details'),
                    icon:"/assets/landing/images/features-page/features4/1.svg"
                },
                 {
                    id:2,
                   title:this.$t('features4.ssl.title'),
                   details:this.$t('features4.ssl.details'),
                   icon:"/assets/landing/images/features-page/features4/2.svg"
                },
                 {
                    id:3,
                    title:this.$t('features4.personalBackup.title'),
                    details:this.$t('features4.personalBackup.details'),
                    icon:"/assets/landing/images/features-page/features4/3.svg"
                },
                 {
                    id:4,
                    title:this.$t('features4.advancedBackup.title'),
                    details:this.$t('features4.advancedBackup.details'),
                    icon:"/assets/landing/images/features-page/features4/4.svg"
                },
            ]
        }
    }
}
</script>

<style>

</style>