<template>
    <section id="features">
        <div
            v-if="$route.path == '/site/about'"
            class="interface text-center pos-t-50"
        >
            <b-container>
                <h2 class="purble-clr f-bold fs-24">
                    {{ $t("about_page.features.header") }}
                </h2>
                <p class="w-630 center-block">
                    {{ $t("about_page.features.sub_header") }}
                </p>
                        <div  class="mt-50">
            <img
                src="/assets/landing/images/about/features/features.svg"
                alt=""
            />
        </div>
            </b-container>
        </div>
        <div v-else>
            <div id="feature">
                <img
                    class="feature-grid"
                    src="/assets/landing/images/features/repeat-grid.svg"
                    alt=""
                />
                <h2>{{ features.title}}</h2>
                <p class="blue-clr">{{ features.description}}</p>
                <img
                    class="feature-grid f-r"
                    src="/assets/landing/images/features/part-circle.svg"
                    alt=""
                />
            </div>

            <div id="feature-boxes">
                <b-container>
                    <b-row>
                        <b-col
                            v-for="(feature, key) in features.items"
                            :key="key"
                            lg="3"
                            md="4"
                            sm="6"
                        >
                            <div class="box-feature">
                                <img
                                    class="p-feature-icon mx-auto"
                                    :src="feature.image"
                                    alt=""
                                    title=""
                                />
                                <h5>{{ feature.title }}</h5>
                                <p>{{ feature.description }}</p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <div class="mx-auto">
                            <b-button
                                @click="goTo(features.btn.target)"
                                class="try-btn w-140"
                                >{{ features.btn.title }}</b-button
                            >
                        </div>
                    </b-row>
                </b-container>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:["features"],
    data() {
        return {
        }
    },
    methods: {
        goTo(page) {
            this.$router.push(page);
        }
    }
};
</script>

<style></style>
