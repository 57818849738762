<template>
    <section class="user_manual_details">
        <b-container>
            <b-row>
                <b-col cols="3">
                    <div>
                        <b-nav vertical class="details-nav">
                            <b-nav-item active class="details-nav-item">{{$t('user_manual')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('intro')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('five_years')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('app')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('prepare')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('sale')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('buy')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('inventory')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">
                                <p v-b-toggle.collapse-1-inner size="sm">{{$t('manufacturing')}}</p>
                                <b-collapse id="collapse-1-inner">
                                    <div>
                                        <b-nav-item class="details-nav-item">{{$t('materials')}}</b-nav-item>
                                        <b-nav-item class="details-nav-item">{{$t('planning')}}</b-nav-item>
                                    </div>
                                </b-collapse>
                            </b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('support')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('create_website')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('collaboration_tools')}}</b-nav-item>
                            <b-nav-item class="details-nav-item">{{$t('knowledge_library')}}</b-nav-item>
                        </b-nav>
                    </div>
                </b-col>
                <b-col>
                    <h4>{{$t('intro')}}</h4>
                    <div>
                        <p class="details_title">{{$t('what_is_erp')}}</p>
                        <p v-html="$t('what_is_erp_details')"></p>
                    </div>
                    <div class="mt-5">
                        <p class="details_title">{{$t('what_is_aptus_title')}}</p>
                        <p>
                            {{$t('what_is_aptus_description')}}
                        </p>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3"></b-col>
                <b-col>
                    <b-img class="details-image" src="/assets/landing/images/user-manual-details.svg"></b-img>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3"></b-col>
                <b-col>
                    <p v-html="$t('as_example_fro_details')">
                    </p>
                    <div>
                        <p class="mt-5 details_title">
                            {{$t('why_aptus_title')}}
                        </p>
                        <p>{{$t('why_aptus_details')}}</p>
                    </div>
                    <div>
                        <p class="mt-5 details_title">{{$t('features_title')}}</p>
                        <p v-html="$t('features_details')">
                        </p>
                    </div>
                    <div>
                        <p class="mt-5 details_title">
                            {{$t('second_features_title')}}
                        </p>
                        <p v-html="$t('second_features_details')"></p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
    }
</script>

<style scoped>

</style>
