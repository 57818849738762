<template>
    <section class="footer-container mt-100">
        <div> 
            <img class="f-r grid" src="/assets/landing/images/footer/repeat-grid.svg" alt="">
        </div>
        <top-footer :topFooter="footer"></top-footer>
        <bottom-footer></bottom-footer>
        <fixed-footer></fixed-footer>
    </section>
</template>

<script>
export default {
     props:["footer"],
}
</script>

<style>

</style>