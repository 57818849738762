<template>
  <div>
    <hero></hero>
    <video-player></video-player>
    <screen></screen>
     <features></features>
     <tour></tour>
     <features-inline></features-inline>
     <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>