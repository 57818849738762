<template>
 <section id="programs">
      <div class="circle">
          <img src="/assets/landing/images/programs-page/programs2/circle.svg" alt="">
        </div>
        <div id="program-boxes">
            <b-container>
                  <b-row>
     <b-col v-for="(program,key) in programs" :key="key" lg="3" md="4" sm="6">
        <div class="box">
        <img  class="p-icon"
         :src="program.icon" alt="" title="" />
         <h5>{{program.title}}</h5>
         <p>{{program.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>

        </div>
  </section>
</template>

<script>
export default {
     data(){
        return {
            programs: [
                {
                    id:1,
                    title:this.$t('programs2.moneyBill.title'),
                    details:this.$t('programs2.moneyBill.details'),
                    icon:"/assets/landing/images/programs/money-bill.svg"
                },
                 {
                    id:2,
                    title:this.$t('programs2.userTie.title'),
                    details:this.$t('programs2.userTie.details'),
                    icon:"/assets/landing/images/programs/user-tie.svg"
                },
                 {
                    id:3,
                    title:this.$t('programs2.metroShop.title'),
                    details:this.$t('programs2.metroShop.details'),
                    icon:"/assets/landing/images/programs/metro-shop.svg"
                },
                 {
                    id:4,
                    title:this.$t('programs2.shoppingBag.title'),
                    details:this.$t('programs2.shoppingBag.details'),
                    icon:"/assets/landing/images/programs/shopping-bag.svg"
                },
                {
                    id:5,
                    title:this.$t('programs2.maleCustomerAdvisor.title'),
                    details:this.$t('programs2.maleCustomerAdvisor.details'),
                    icon:"/assets/landing/images/programs/male-customer-advisor.svg"
                },
                {
                    id:6,
                    title:this.$t('programs2.projectDiagram.title'),
                    details:this.$t('programs2.projectDiagram.details'),
                    icon:"/assets/landing/images/programs/project-diagram.svg"
                },
                {
                    id:7,
                    title:this.$t('programs2.settings.title'),
                    details:this.$t('programs2.settings.details'),
                    icon:"/assets/landing/images/programs/settings.svg"
                },
                 {
                    id:8,
                    title:this.$t('programs2.truck.title'),
                    details:this.$t('programs2.truck.details'),
                    icon:"/assets/landing/images/programs/truck.svg"
                }
            ]
        }
    },
    methods:{
        goTo(page) {
    this.$router.push('/site/'+page);
  },
    }
}
</script>

<style>

</style>
