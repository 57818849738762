<template>
  <div>
      <section id="tour2">
     <div class="tour-circle">
          <img src="/assets/landing/images/features-page/tour2/circle.svg" alt="">
     </div>
    
    <b-container>
         <div class="tour2 t-r">
         <h2 class="white-clr f-bold fs-28">{{$t('features_page.tour2.header')}}</h2>
          <p class="white-clr pos-l-100 text-center w-1000 pos-t-10">{{$t('features_page.tour2.sub_header')}}</p>
    </div> 
        </b-container>           
</section>

    <tour2-boxes></tour2-boxes>

  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>