<template>
  <div>
      <section id="tour">
     <div class="tour-circle">
          <img src="/assets/landing/images/about/tour/circle.svg" alt="">
     </div>
    
    <b-container>
         <div class="t-r">
         <h2 class="white-clr f-bold fs-28">{{$t('about_page.tour.header')}}</h2>
         <p class="white-clr pos-l-100 center-block w-630 pos-t-10">{{$t('about_page.tour.sub_header')}}</p>
    </div> 
        </b-container>           
</section>

    <tour-boxes></tour-boxes>

  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>