<template>

<b-navbar toggleable>
     <b-nav id="our_navbar" align="left">
    <b-nav-item class="logo pdl-180 pos-r-50">
        <img src="/assets/landing/images/hero/navbar/logo.svg" alt="" title="" />
    </b-nav-item>
    <b-nav-item
      @click="goTo('/site')"
     :class="$route.path=='/site/home' ||
     $route.path=='/site' ?'active nav-fix':'nav-fix'">{{$t('navbar.home')}}</b-nav-item>
    <b-nav-item
     @click="goTo('/site/about')"
    :class="$route.path=='/site/about'?'active':''"
    >{{$t('navbar.about')}}</b-nav-item>
    <b-nav-item
     @click="goTo('/site/features')"
    :class="$route.path=='/site/features'?'active':''"
    >{{$t('navbar.features')}}</b-nav-item>
    <b-nav-item :class="$route.path=='/site/bunches'?'active':''" @click="goTo('/site/bunches')">{{$t('navbar.bunches')}}</b-nav-item>
    <b-nav-item class="last_nav pdl-100" :class="$route.path=='/site/faq'?'active':''"
    @click="goTo('/site/faq')"
    >{{$t('navbar.technical_support')}}</b-nav-item>
    <b-nav class="pos-r-50 try_block">
        <b-nav-item><b-button class="try-btn">{{$t('try_now')}}</b-button></b-nav-item>
      <b-nav-item @click="changeLanguage()">{{$t('lang')}}</b-nav-item>
    </b-nav>
  </b-nav>

  </b-navbar>
</template>

<script>
export default {
  data(){
    return{
      lang: '',
    }
  },
methods: {
  goTo(page) {
  this.$router.push(page);
  },
  changeLanguage(){
    this.lang  = localStorage.getItem('landing_lang');
    if(this.lang =='en') {
      localStorage.setItem('landing_lang',"ar")
    }else{
      localStorage.setItem('landing_lang',"en")
    }
    location.reload();
  }
}
}
</script>

<style>

</style>
