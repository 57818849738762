<template>
 <section id="extra_features">

     <div id="feature">
        <img class="feature-grid f-r" src="/assets/landing/images/features/part-circle.svg" alt="">
        </div>

        <div class="interface text-center pos-t-50">
           <b-container>
                <h2 class="purble-clr f-bold fs-24">{{$t('accounting_page.extra_features.header')}}</h2>
            <p class="w-630 center-block">{{$t('accounting_page.extra_features.sub_header')}}</p>
                  <img height="400" src="/assets/landing/images/programs-page/accounting/extra-features/extra-features.svg" alt="">
          </b-container>
        </div>
        <div id="feature-boxes" class="mt-50 t-r">
            <b-container>
                  <b-row>
    <b-col class="extra_features" v-for="(feature,key) in features" :key="key" lg="4" md="6" sm="12">
        <div class="mt-50">
        <img  class="p-feature-icon f-r border-r-50per"
         :src="feature.icon" alt="" title="" />
         <span class="f-r pad-t-15 pad-r-15 w-200">{{feature.title}}</span>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
  </section> 
</template>

<script>
export default {
    data(){ 
        return {
            features: [
                {
                    id:1,
                    title:this.$t('feature.calculator'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/calculator.svg"
                },
                {
                    id:2,
                    title:this.$t('feature.print'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/print.svg"
                },
                {
                    id:3,
                    title:this.$t('feature.money-check-alt'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/money-check-alt.svg"
                },

                 {
                    id:4,
                    title:this.$t('feature.open-graph'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/open-graph.svg"
                },
                {
                    id:5,
                    title:this.$t('feature.view-quilt'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/view-quilt.svg"
                },
                {
                    id:6,
                    title:this.$t('feature.file-export'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/file-export.svg"
                },

                 {
                    id:7,
                    title:this.$t('feature.unlock-alt'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/unlock-alt.svg"
                },
                {
                    id:8,
                     title:this.$t('feature.user-tie'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/user-tie.svg"
                },
                {
                    id:9,
                    title:this.$t('feature.money-bill-wave'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/money-bill-wave.svg"
                },

                 {
                    id:10,
                    title:this.$t('feature.star-half'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/star-half.svg"
                },
                {
                    id:11,
                    title:this.$t('feature.file-archive'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/file-archive.svg"
                },
                {
                    id:12,
                    title:this.$t('feature.money-bill-alt'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/money-bill-alt.svg"
                },
                {
                    id:13,
                    title:this.$t('feature.cash-out'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/cash-out.svg"
                },
                {
                    id:14,
                    title:this.$t('feature.cash-in'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/cash-in.svg"
                },
                {
                    id:15,
                    title:this.$t('feature.book'),
                    icon:"/assets/landing/images/programs-page/accounting/extra-features/book.svg"
                },
                
            ]
        }
    }
}
</script>

<style>

</style>