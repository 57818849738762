<template>
    <div>
        <section id="tour5">

            <b-container>
                <div class="tour t-c pos-l-150">
                    <h2 class="white-clr f-bold fs-28">{{$t('privacy_policy_page.tour5.header')}}</h2>
                </div>
            </b-container>
        </section>


        <section id="tour5-conditions">

            <ul class="t-r conditions grey-dark-clr">
                <li v-for="(item,index) in tours" :key="index">
                    <p>{{index+1}}. {{item.title}}</p>
                    <p>{{item.details}}</p>
                    <p v-if="item.details2!==null"><br>
                        <span>{{item.details2}}</span>
                    </p>
                </li>

            </ul>

        </section>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                tours: [
                    {
                        title: this.$t('tour.agreement'),
                        details: this.$t('tour.agreement_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.payment'),
                        details: this.$t('tour.payment_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.automatic_renewal'),
                        details: this.$t('tour.automatic_renewal_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.security'),
                        details: this.$t('tour.security_details'),
                        details2: this.$t('tour.security_details2'),
                    },
                    {
                        title: this.$t('tour.support'),
                        details: this.$t('tour.support_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.maintenance'),
                        details: this.$t('tour.maintenance_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.save_data'),
                        details: this.$t('tour.save_data_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.laws'),
                        details: this.$t('tour.laws_details'),
                        details2: this.$t('tour.laws_details2'),
                    },
                    {
                        title: this.$t('tour.responsibility'),
                        details: this.$t('tour.responsibility_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.compensation'),
                        details: this.$t('tour.compensation_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.transferable'),
                        details: this.$t('tour.transferable_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.adjustment'),
                        details: this.$t('tour.adjustment_details'),
                        details2: null,
                    },
                    {
                        title: this.$t('tour.copy_agreement'),
                        details: this.$t('tour.copy_agreement_details'),
                        details2: null,
                    }
                ]
            }
        }
    }
</script>

<style>

</style>
