<template>
    <section class="technical_support">
        <b-container>
            <b-row align-h="center">
                <p>طلب دعم فني</p>
            </b-row>
            <b-row align-h="center">
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span class="support-span">مشترك في آبتوس</span>
                        </b-col>
                        <b-col>
                            <b-form-radio-group name="">
                                <b-form-radio class="support-yes" value="yes">نعم</b-form-radio>
                                <b-form-radio class="support-no" value="no">لا</b-form-radio>
                            </b-form-radio-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span class="support-span">إرفاق ملف أو صورة مساندة</span>
                        </b-col>
                        <b-col>
                            <b-form-group label="" label-for="file-small" label-cols-sm="2" label-size="sm">
                                <b-form-file id="file-small" size="sm"></b-form-file>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <b-form-textarea
                                    class="support-input"
                                    size="lg"
                                    placeholder=""
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span class="support-span">اكتب رمز التحقق الظاهر في الصورة</span><br>
                            <span class="support-span">UYC145</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                label=""
                                description="">
                                <b-form-input
                                    class="support-input"
                                    placeholder=""
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row align-h="center">
                        <b-col cols="4">
                            <b-button type="submit">send</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>
    .support-span {
        float: right;
        font-family: 'Tajawal';
    }

    .support-input {
        border-radius: 25px;
        background: #E9E9E9;
    }

    .technical_support p{
        color: #9753A0;
        font-weight: bold;
        font-family: 'Tajawal';
    }

    .support-no{
        margin-right: 80px;
    }
</style>
