<template>
  <div>
      <section id="tour3">
     <div class="tour3-circle">
          <img src="/assets/landing/images/features-page/tour2/circle.svg" alt="">
     </div>
     <div class="qmark">
          <img src="/assets/landing/images/programs-page/accounting/tour3/qmark.svg" alt="">
     </div>
    
    <b-container>
         <div class="tour3 t-r pos-l-100">
         <p  class="cyan-clr text-center pad-l-150 fs-22">{{$t('accounting_page.tour3.header')}}</p>
            <p class="white-clr w-1000 text-center fs-16">{{$t('accounting_page.tour3.sub_header')}}</p>
    </div> 
        </b-container>           
</section>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>