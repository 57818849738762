<template>
 <section id="user-manuals">
      <div class="circle">
          <img src="/assets\landing\images\about\video-player\part-circle.svg" alt="">
        </div>
        <div id="user-manual">
            <b-container>
                  <b-row>
     <b-col v-for="(user,index) in users" :key="index" lg="3" md="4" sm="6">
        <div class="box">
         <p class="user-circle">{{index +1}}</p>
         <h5>{{user.title}}</h5>
         <p>{{user.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>

        </div>
  </section>
</template>

<script>
export default {
     data(){
        return {
            users: [
                {
                    id:1,
                    title: this.$t('user_manual_page.intro'),
                    details: this.$t('user_manual_page.intro_details'),
                   },
                {
                    id:2,
                    title: this.$t('user_manual_page.five_days'),
                    details: this.$t('user_manual_page.five_days_details'),
                },
                {
                    id:3,
                    title: this.$t('user_manual_page.app'),
                    details: this.$t('user_manual_page.app_details'),
                },
                {
                    id:4,
                    title: this.$t('user_manual_page.preparation'),
                    details: this.$t('user_manual_page.preparation_details'),
                },
                {
                    id:5,
                    title: this.$t('user_manual_page.sale'),
                    details: this.$t('user_manual_page.sale_details'),
                },
                 {
                    id:6,
                    title: this.$t('user_manual_page.purchase'),
                    details: this.$t('user_manual_page.purchase_details'),
                },
                 {
                    id:7,
                    title: this.$t('user_manual_page.inventory'),
                    details: this.$t('user_manual_page.inventory_details'),
                },
                {
                    id:8,
                    title: this.$t('user_manual_page.accounting'),
                    details: this.$t('user_manual_page.accounting_details'),
                },
                {
                    id:9,
                    title: this.$t('user_manual_page.hrm'),
                    details: this.$t('user_manual_page.hrm_details')
                },
                {
                    id:10,
                    title: this.$t('user_manual_page.manufacturing'),
                    details: this.$t('user_manual_page.manufacturing_details'),
                },
                {
                    id:11,
                    title: this.$t('user_manual_page.support'),
                    details: this.$t('user_manual_page.support_details'),
                },

                 {
                    id:12,
                    title: this.$t('user_manual_page.website'),
                    details: this.$t('user_manual_page.website_details')
                },
                 {
                    id:13,
                    title: this.$t('user_manual_page.tools'),
                    details: this.$t('user_manual_page.tools_details'),
                },
                  {
                    id:14,
                    title: this.$t('user_manual_page.library'),
                    details: this.$t('user_manual_page.library_details'),
                },


            ]
        }
    },
    methods:{
        goTo(page) {
    this.$router.push('/site/'+page);
  },
    }
}
</script>

<style>

</style>
