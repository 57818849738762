<template>
  <div id="hero" :class="hero.client?'h-auto mb-200':''">
     <nav-bar></nav-bar>
     <head-bar :header="header" :hero="hero"></head-bar>
  </div>
</template>

<script>
export default {
  props:["header"],
data(){
  return {
    hero:{}
  }
},
mounted(){
  if(this.$route.path=="/site/home" || this.$route.path=="/site") {
    this.hero = {
       header:this.$t('hero.header'),
      sub_header:this.$t('hero.sub_header'),
      img:"/assets/landing/images/hero/header/hero.svg",
    }
  }
  else  if(this.$route.path=="/site/about") {
    this.hero = {
      header:this.$t('about_page.hero.header'),
      sub_header:this.$t('about_page.hero.sub_header'),
      img:"/assets/landing/images/about/hero/header/hero.svg",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.about')
      }
    }
  }
   else  if(this.$route.path=="/site/features") {
    this.hero = {
      header:this.$t('features_page.hero.header'),
      sub_header:this.$t('features_page.hero.sub_header'),
      img:"/assets/landing/images/features-page/hero/header/hero.svg",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.features')
      }
    }
  }
  else  if(this.$route.path=="/site/programs") {
    this.hero = {
      header:this.$t('programs_page.hero.header'),
      sub_header:this.$t('programs_page.hero.sub_header'),
      img:"/assets/landing/images/programs-page/hero/header/hero.svg",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.programs')
      }
    }
  }
  else  if(this.$route.path=="/site/programs/accounting") {
    this.hero = {
      header:this.$t('accounting_page.hero.header'),
      sub_header:this.$t('accounting_page.hero.sub_header'),
      footer:this.$t('hero.footer'),
      video:"https://www.youtube.com/embed/ZrtVRGbN4I8",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.programs'),
        child2:this.$t('route.accounting')
      }
    }
  }
    else  if(this.$route.path=="/site/clients") {
      this.hero = {
        client:true,
        route:{
        parent:this.$t('route.home'),
        child:this.$t('route.clients'),
      }
      }
    }

      else  if(this.$route.path=="/site/said-about-us") {
    this.hero = {
      header:this.$t('said_about_us_page.hero.header'),
      sub_header:this.$t('said_about_us_page.hero.sub_header'),
      img:"/assets/landing/images/said-about-us-page/hero/header/hero.svg",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.said_about_us')
      }
    }
  }
       else  if(this.$route.path=="/site/reports") {
    this.hero = {
      header:this.$t('reports_page.hero.header'),
      sub_header:this.$t('reports_page.hero.sub_header'),
      footer2:this.$t('reports_page.hero.footer'),
      img:"/assets/landing/images/reports-page/hero/header/hero.svg",
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.reports')
      }
    }
  }

  else  if(this.$route.path=="/site/users-manual") {
    this.hero = {
       header:this.$t('users_manual_page.hero.header'),
      sub_header:this.$t('users_manual_page.hero.sub_header'),
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.users_manual')
      }
    }
  }

  else  if(this.$route.path=="/site/privacy-policy") {
    this.hero = {
       header:this.$t('privacy_policy_page.hero.header'),
      header2:this.$t('privacy_policy_page.hero.header2'),
      sub_header:this.$t('privacy_policy_page.hero.sub_header'),
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.privacy_policy')
      }
    }
  }

   else  if(this.$route.path=="/site/pages/details") {
    this.hero = {
       route:{
        parent:this.$t('route.home'),
        child:this.$t('route.users_manual'),
        child2:this.$t('route.intro')
      }
    }
  }
   else if (this.$route.path == '/site/bunches' || this.$route.path == '/site/bunches-details'){
       this.hero = {
           header:this.$t('bunch.header'),
           sub_header:this.$t('bunch.sub_header'),
           img:"/assets/landing/images/bunch.svg",
           route:{
               parent:this.$t('route.home'),
               child:this.$t('route.bunches')
           }
       }
  }
  else if (this.$route.path == '/site/faq'){
       this.hero = {
           header:this.$t('faq_page.header'),
           header2:this.$t('faq_page.header2'),
           sub_header:this.$t('faq_page.sub_header'),
           route:{
               parent:this.$t('route.home'),
               child:this.$t('route.faq')
           }
       }
  }
  else if (this.$route.path == '/site/request-experiment'){
       this.hero = {
           header:this.$t('request_experiment_page.header'),
           sub_header:this.$t('request_experiment_page.sub_header'),
      img:"/assets/landing/images/request-experiment-page/hero/header/hero.svg",
           route:{
               parent:this.$t('route.home'),
               child:this.$t('route.request_experiment')
           }
       }
  }

  else if (this.$route.path == '/site/support'){
      this.hero = {
          header:this.$t('technical_support.header'),
          sub_header:this.$t('technical_support.sub_header'),
          img:"/assets/landing/images/support.svg",
          route:{
              parent:this.$t('route.home'),
              child:this.$t('route.support')
          }
      }
  }

}
}
</script>

<style>


</style>
