<template>
    <section class="about-us-container">
        <div>
            <img
                class="f-l part-circle"
                src="/assets/landing/images/about-us/part-circle.svg"
                alt="about us"
            />
        </div>
        <b-container>
            <b-row class="about-row">
                <b-col cols="4" class="about-img pos-l-30">
                    <img
                        :src="aboutUs.image"
                        alt="about us"
                    />
                </b-col>
                <b-col class="about-col">
                    <div class="about-details">
                        <p class="about-title">{{aboutUs.title}}</p>
                        <p class="about-sub-title">
                            {{aboutUs.title}}
                        </p>
                        <p class="about-content">
                            {{aboutUs.description}}
                        </p>
                        <b-button
                            @click="goTo(aboutUs.btn.target)"
                            class="try-btn w-140 pos-t-50"
                            >
                            {{aboutUs.btn.title}}</b-button
                        >
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    name: "AboutUs",
    props:["aboutUs"],
    methods: {
        goTo(page) {
            this.$router.push(page);
        }
    }
};
</script>

<style></style>
