<template>
 <section id="multi-reports">
        <div class="multi-reports">
        <h2 class="purble-clr text-center ">{{$t('accounting_page.multi_reports.header')}}</h2>
        <img class="feature-grid f-r" src="/assets/landing/images/programs-page/accounting/multi-reports/circle.svg" alt="">
        </div>
        <div id="multi-reports-boxes">
            <b-container>
                  <b-row>
    <b-col v-for="(feature,key) in reports" :key="key" lg="3" md="4" sm="6">
        <div class="accounting-box text-center h-250">
        <img  class="mx-auto"
         :src="feature.icon" alt="" title="" />
         <h5 class="pad-t-15 grey-light-clr">{{feature.title}}</h5>
         <p  class="pad-t-15 grey-dark-clr">{{feature.details}}</p>
        </div>
    </b-col>
    </b-row>
            </b-container>
        </div>
  </section> 
</template>

<script>
export default {
    data(){ 
        return {
            reports: [
                {
                    id:1,
                    title:this.$t('multiReports.featherSettings.title'),
                    details:this.$t('multiReports.featherSettings.details'),
                    icon:"/assets/landing/images/programs-page/accounting/multi-reports/feather-settings.svg"
                },
                {
                    id:2,
                    title:this.$t('multiReports.addressCard.title'),
                    details:this.$t('multiReports.addressCard.details'),
                    icon:"/assets/landing/images/programs-page/accounting/multi-reports/address-card.svg"
                },
                {
                    id:3,
                    title:this.$t('multiReports.fileSignature.title'),
                    details:this.$t('multiReports.fileSignature.details'),
                    icon:"/assets/landing/images/programs-page/accounting/multi-reports/file-signature.svg"
                },
                {
                    id:4,
                    title:this.$t('multiReports.folderOpen.title'),
                    details:this.$t('multiReports.folderOpen.details'),
                    icon:"/assets/landing/images/programs-page/accounting/multi-reports/folder-open.svg"
                }
            ]
        }
    }
}
</script>

<style>

</style>