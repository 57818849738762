<template>
  <section id="videos">
         <b-container>
                  <b-row>
          <b-col class="video-div" v-for="(video,key) in videos.items" :key="key" lg="4" md="12" sm="12">
            <img v-if="video.description==''" :src="video.image" alt="">
            <i  v-if="video.description==''" class="fa fa-youtube-play youtube-video"></i>
            <div class="video-details"  v-if="video.description==''">
             <p  class="video-name">{{video.title }}</p>
             <p  class="video-job">{{video.sub_title}}</p>
             </div>
          </b-col>
          <div class="center-block mb-30">
            <b-button @click="goTo(videos.btn.target)" class="try-btn w-140">{{videos.btn.title}}</b-button>
          </div>
            </b-row>
         </b-container>
    </section>
</template>

<script>
export default {
    props:["videos"],
data(){
    return {}
},
methods:{
    goTo(page) {
            this.$router.push(page);
        }
}
}
</script>

<style>
</style>
